import { Grid, Stack, Typography } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ProductAdministratorSupportedServiceChannelAvailability } from "../../../../services/core-api-adapter";

type ServiceAvailabilityData = {
  [key: string]: string | null | undefined;
};

type ServiceAvailabilityExtras = { label: string; value: string }[];

interface ServiceAvailabilityListProps {
  data: ProductAdministratorSupportedServiceChannelAvailability[];
  delimiter?: string;
  extras?: {
    data: ServiceAvailabilityExtras;
  };
  sx?: any;
}

function findSequences(
  serviceAvailability: ProductAdministratorSupportedServiceChannelAvailability[]
) {
  const dataSet: ServiceAvailabilityData = {};
  serviceAvailability.forEach(
    (item) => (dataSet[item.day] = item.operatingHours)
  );

  const result = [];
  let fromDay = null;
  let toDay = null;
  let value = null;

  for (const day in dataSet) {
    const dayValue = dataSet[day];

    if (fromDay === null) {
      fromDay = day;
      toDay = day;
      value = dayValue;
    } else if (value !== dayValue && fromDay !== undefined) {
      result.push({ from: fromDay, to: toDay, value: value });
      fromDay = day;
      toDay = day;
      value = dayValue;
    } else {
      toDay = day;
    }
  }

  if (fromDay !== null && fromDay !== undefined) {
    result.push({ from: fromDay, to: toDay, value: value });
  }

  return result;
}

export default function ServiceAvailabilityList({
  data,
  delimiter,
  extras,
  sx,
}: ServiceAvailabilityListProps) {
  const dataSequences = useMemo(() => findSequences(data), [data]);
  const { t } = useTranslation();

  return (
    <Stack
      role="list"
      spacing={1}
      aria-label={t("ServiceAvailabilityList.label")}
    >
      <Stack spacing={1}>
        {dataSequences.map((dataItem) => {
          const value = dataItem.value || t("common.closed");

          const label =
            dataItem.from === dataItem.to
              ? t(`ServiceAvailabilityList.${dataItem.from}.title` as any)
              : t(`ServiceAvailabilityList.${dataItem.from}.title` as any) +
                ` ${delimiter || " - "} ` +
                t(`ServiceAvailabilityList.${dataItem.to}.title` as any);

          return (
            <Grid
              key={dataItem.from + "-" + dataItem.to}
              display="flex"
              justifyContent="space-between"
              role="listitem"
              color={(theme) => theme.palette.grey[500]}
              sx={{ ...sx }}
            >
              <Typography variant="body2" color="inherit">
                {label}
              </Typography>
              <Typography variant="body2" color="inherit">
                {value}
              </Typography>
            </Grid>
          );
        })}
      </Stack>

      <Stack spacing={1}>
        {extras?.data &&
          extras.data?.map((specialDataItem) => {
            return (
              <Stack
                key={specialDataItem.label}
                direction="row"
                justifyContent="space-between"
                color={(theme) => theme.palette.grey[500]}
                sx={{ ...sx }}
              >
                <Typography variant="body2" color="inherit">
                  {t(
                    `ServiceAvailabilityList.${specialDataItem.label}.title` as any
                  )}
                </Typography>
                <Typography variant="body2" color="inherit">
                  {specialDataItem.value}
                </Typography>
              </Stack>
            );
          })}
      </Stack>
    </Stack>
  );
}

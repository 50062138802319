import { assign, fromPromise, setup } from "xstate";

import {
  MemberConsultationOutcome,
  getMemberConsultations,
  startMemberConsultation,
  getMemberConsultationOutcome,
  GetMemberConsultationsItem,
  getMemberships,
  Member,
  MembershipType,
  submitRating,
  UserRating,
  ServiceRating,
  MemberConsultationType,
  ProductAdministratorSupportedServiceChannelAvailability,
  getMemberProductPaymentRates,
  MemberProductPaymentRatesItem,
  MemberConsultationStatus,
  getDefaultPayGMedicalAssistanceAvailabilityData,
  getMemberServiceOfferingUsage,
  MemberServiceOfferingType,
  MemberServiceOfferingUsage,
  MemberServiceOfferingStatus,
} from "../../../../services/core-api-adapter";
import {
  getCurrentFormattedDay,
  getCurrentFormattedTime,
} from "../../../../utils";
import {
  FeatureToggleIdentifier,
  isFeatureEnabled,
} from "@/services/feature-toggle-adapter";

const MAX_POLLING_TIME_IN_SECONDS =
  Number(
    import.meta.env.VITE_APP_INTERCARE_MEDICAL_ASSISTANCE_MAX_POLLING_TIME
  ) || 100;
const POLLING_INTERVAL_IN_MILLISECONDS =
  Number(
    import.meta.env.VITE_APP_INTERCARE_MEDICAL_ASSISTANCE_POLLING_INTERVAL
  ) || 2000;

interface Context {
  activeConsultationListResponse: Array<GetMemberConsultationsItem>;
  membersList: Array<Member>;
  selectedDependent: Member | null;
  selectedConsultation: GetMemberConsultationsItem | null;
  consultationOutcomeResponse: GetMemberConsultationsItem | null;
  consultationRatesResponse: MemberProductPaymentRatesItem[] | null;
  consultationType: MemberConsultationType;
  userRating: UserRating;
  consultationAvailabilityData: ProductAdministratorSupportedServiceChannelAvailability[];
  startConsultationTime: Date | null;
  queryParamConsultationId: string;
  primarySymptomIds: string[];
  isDirectToDoctorSelected: boolean;
  memberServiceOfferingUsages: MemberServiceOfferingUsage[] | null;
  isPromoCodeApplied: boolean;
}

const defaultAvailabilityData =
  getDefaultPayGMedicalAssistanceAvailabilityData();

const initialContextValues: Context = {
  activeConsultationListResponse: [],
  membersList: [],
  selectedDependent: null,
  queryParamConsultationId: "",
  selectedConsultation: null,
  consultationOutcomeResponse: null,
  consultationRatesResponse: null,
  consultationType: MemberConsultationType.SYMPTOM_ASSESSMENT,
  userRating: {
    rating: 0,
    comment: "",
    serviceType: ServiceRating.CONSULTATION,
    ratedServiceId: "",
  },
  consultationAvailabilityData: defaultAvailabilityData,
  startConsultationTime: null,
  isDirectToDoctorSelected: false,
  primarySymptomIds: [],
  memberServiceOfferingUsages: null,
  isPromoCodeApplied: false,
};

export const eventNames = {
  BACK_BUTTON_CLICKED: "BACK_BUTTON_CLICKED",
  GO_HOME_BUTTON_CLICKED: "GO_HOME_BUTTON_CLICKED",
  NEXT_BUTTON_CLICKED: "NEXT_BUTTON_CLICKED",
  CONTINUE_BUTTON_CLICKED: "CONTINUE_BUTTON_CLICKED",
  MORE_INFO_BUTTON_CLICKED: "MORE_INFO_BUTTON_CLICKED",
  START_CONSULTATION_BUTTON_CLICKED: "START_CONSULTATION_BUTTON_CLICKED",
  DEPENDANT_SELECTED: "DEPENDANT_SELECTED",
  ACTIVE_CONSULTATION_SELECTED: "ACTIVE_CONSULTATION_SELECTED",
  USER_REDIRECTED: "USER_REDIRECTED",
  RATING_TRY_AGAIN: "RATING_TRY_AGAIN",
  DIRECT_TO_DOCTOR_BUTTON_CLICKED: "DIRECT_TO_DOCTOR_BUTTON_CLICKED",
  FIND_A_DOCTOR_BUTTON_CLICKED: "FIND_A_DOCTOR_BUTTON_CLICKED",
  NURSE_CONSULTATION_BUTTON_CLICKED: "NURSE_CONSULTATION_BUTTON_CLICKED",
  SKIP_BUTTON_CLICKED: "SKIP_BUTTON_CLICKED",
};

export const intercareFlowStateMachine = setup({
  types: {
    context: {} as Context,
  },
  actions: {
    addActiveConsultationListResponseToContext: assign(({ event }) => {
      return { activeConsultationListResponse: event.output };
    }),
    addMembersListToContext: assign(({ event }) => {
      return { membersList: event.output.memberships[0].members };
    }),
    addMemberServiceOfferingUsageResponseToContext: assign(({ event }) => {
      return { memberServiceOfferingUsages: event.output.body };
    }),
    addSelectedDependentToContext: assign(({ event }) => {
      return { selectedDependent: event.input };
    }),
    addConsultationOutcomeResponseToContext: assign(({ event }) => {
      return { consultationOutcomeResponse: event.input || event.output };
    }),
    addSelectedConsultationToContext: assign(({ event }) => {
      return { selectedConsultation: event.input };
    }),
    addIsDirectToDoctorToContext: assign(() => {
      return { isDirectToDoctorSelected: true };
    }),
    removeIsDirectToDoctorToContext: assign(() => {
      return { isDirectToDoctorSelected: false };
    }),
    addStartConsultationResponseToContext: assign(({ event }) => {
      return { selectedConsultation: event.input || event.output };
    }),
    addUserRatingToContext: assign(({ event }) => {
      return { userRating: event.input || event.output };
    }),
    addConsultationRatesResponseToContext: assign(({ event }) => {
      return { consultationRatesResponse: event.output };
    }),
    addIsPromoCodeAppliedToContext: assign(() => {
      return { isPromoCodeApplied: true };
    }),
  },
  actors: {
    getActiveConsultations: fromPromise(async () => {
      return await getMemberConsultations();
    }),
    getMemberships: fromPromise(async () => {
      return await getMemberships();
    }),
    getMemberServiceOfferingUsage: fromPromise(async () => {
      return await getMemberServiceOfferingUsage(
        MemberServiceOfferingType.CONSULTATIONS
      );
    }),
    getConsultationOutcome: fromPromise(
      async ({ input }: { input: { context: Context } }) => {
        const consultationId =
          input.context.selectedConsultation?.consultationId ||
          input.context.queryParamConsultationId;
        return await getMemberConsultationOutcome(consultationId);
      }
    ),
    getMemberConsultationRates: fromPromise(async () => {
      return await getMemberProductPaymentRates();
    }),
    startConsultation: fromPromise(
      async ({ input }: { input: { context: Context } }) => {
        const isPromoCodesFeatureEnabled = isFeatureEnabled(
          FeatureToggleIdentifier.ENABLE_PROMO_CODES
        );

        const { isPromoCodeApplied } = input.context;
        const selectedMemberId =
          input.context.selectedDependent?.memberDetails?.id ||
          input.context.membersList.find(
            (member) =>
              member.membershipDetails.type === MembershipType.MAIN_MEMBER
          )?.memberDetails?.id ||
          "";

        let consultationType: MemberConsultationType =
          input.context.consultationType;
        let primarySymptomIds: string[] = [];

        if (input.context.isDirectToDoctorSelected) {
          consultationType = MemberConsultationType.DIGITAL_DOCTOR_CONSULT;
        }

        if (input.context.primarySymptomIds) {
          primarySymptomIds = input.context.primarySymptomIds;
        }

        if (isPromoCodesFeatureEnabled) {
          return await startMemberConsultation(
            consultationType,
            selectedMemberId,
            primarySymptomIds,
            isPromoCodeApplied
          );
        } else {
          return await startMemberConsultation(
            consultationType,
            selectedMemberId,
            primarySymptomIds
          );
        }
      }
    ),
    submitRating: fromPromise(
      async ({ input }: { input: { context: Context } }) => {
        return await submitRating({
          rating: input.context.userRating.rating,
          comment: input.context.userRating?.comment || "",
          serviceType:
            input.context.userRating?.serviceType || ServiceRating.CONSULTATION,
          ratedServiceId: input.context.selectedConsultation?.consultationId,
        });
      }
    ),
  },
  guards: {
    hasConsultationIdinQueryParam: function ({ context }) {
      return context.queryParamConsultationId !== "";
    },
    hasPaymentSucceededAndIsDirectToDoctor: function ({ context }) {
      return Boolean(
        context.consultationOutcomeResponse?.outcome ===
          MemberConsultationOutcome.PAYMENT_SUCCEEDED &&
          context.isDirectToDoctorSelected
      );
    },
    isOutsideOperatingHours: function ({ context }) {
      const currentTime = getCurrentFormattedTime();
      const currentDay = getCurrentFormattedDay();

      const today = new Date();
      const isChristmasOrNewYearsDay =
        (today.getMonth() === 11 && today.getDate() === 25) ||
        (today.getMonth() === 0 && today.getDate() === 1);

      if (isChristmasOrNewYearsDay) {
        return true;
      }

      const operatingHours = context.consultationAvailabilityData.find(
        (day) => day.day === currentDay
      );
      const [openingTime, closingTime] = operatingHours?.operatingHours.split(
        " - "
      ) as any;

      const isOutsideOperatingHours =
        currentTime < openingTime || currentTime > closingTime;
      return isOutsideOperatingHours;
    },
    hasNoActiveConsultationsAndIsOutsideOperatingHours: function ({ context }) {
      const hasActiveConsultations =
        context.activeConsultationListResponse.length > 0;

      const currentTime = getCurrentFormattedTime();
      const currentDay = getCurrentFormattedDay();

      const operatingHours = context.consultationAvailabilityData.find(
        (day) => day.day === currentDay
      );
      const [openingTime, closingTime] = operatingHours?.operatingHours.split(
        " - "
      ) as any;

      const isOutsideOperatingHours =
        currentTime < openingTime || currentTime > closingTime;
      return isOutsideOperatingHours && !hasActiveConsultations;
    },
    hasAtLeastOneActiveConsultations: function ({ context }) {
      return context.activeConsultationListResponse.length > 0;
    },
    hasDependentsAndNoActiveConsultations: function ({ context }) {
      return (
        context.activeConsultationListResponse.length === 0 &&
        context.membersList.length > 1
      );
    },
    hasNoDependentsAndNoActiveConsultations: function ({ context }) {
      const dependentsListWithoutMainMember = context.membersList.filter(
        (member) => member.membershipDetails.type !== MembershipType.MAIN_MEMBER
      );

      return (
        context.activeConsultationListResponse.length === 0 &&
        dependentsListWithoutMainMember.length === 0
      );
    },
    hasActiveConsultationsAndIsOutsideOperatingHours: function ({ context }) {
      const hasActiveConsultations =
        context.activeConsultationListResponse.length > 0;

      const currentTime = getCurrentFormattedTime();
      const currentDay = getCurrentFormattedDay();

      const operatingHours = context.consultationAvailabilityData.find(
        (day) => day.day === currentDay
      );
      const [openingTime, closingTime] = operatingHours?.operatingHours.split(
        " - "
      ) as any;

      return (
        (currentTime <= openingTime || currentTime >= closingTime) &&
        hasActiveConsultations
      );
    },
    hasDependents: function ({ context }) {
      return context.membersList.length > 1;
    },
    selectedConsultationHasOutstandingPayment: function ({ context }) {
      const { chargeAmountFormatted, chargeCode, outcome } =
        context.selectedConsultation as any;

      return Boolean(
        (outcome === MemberConsultationOutcome.PAYMENT_REQUESTED ||
          outcome === MemberConsultationOutcome.PAYMENT_FAILED) &&
          chargeAmountFormatted &&
          chargeCode
      );
    },
    selectedConsultationHasOutstandingRefund: function ({ context }) {
      return (
        context.selectedConsultation?.outcome ===
        MemberConsultationOutcome.REFUND_REQUESTED
      );
    },
    selectedConsultationHasOutstandingPaymentWithNoCharge: function ({
      context,
    }) {
      const { chargeAmountFormatted, chargeCode, outcome } =
        context.selectedConsultation as any;
      return Boolean(
        (outcome === MemberConsultationOutcome.PAYMENT_REQUESTED ||
          outcome === MemberConsultationOutcome.PAYMENT_FAILED) &&
          (!chargeAmountFormatted || !chargeCode)
      );
    },
    userHasFindAHospitalOutcome: function ({ context }) {
      return (
        context.consultationOutcomeResponse?.outcome ===
        MemberConsultationOutcome.EMERGENCY_CONSULT
      );
    },
    userHasArchivedConsultation: function ({ context }) {
      return (
        context.consultationOutcomeResponse?.status ===
        MemberConsultationStatus.ARCHIVED
      );
    },
    userHasUnsuccessfulPaymentOutcome: function ({ context }) {
      return (
        context.consultationOutcomeResponse?.outcome ===
        MemberConsultationOutcome.PAYMENT_FAILED
      );
    },
    userHasFindADoctorOutcome: function ({ context }) {
      return (
        context.consultationOutcomeResponse?.outcome ===
        MemberConsultationOutcome.INPERSON_CONSULT
      );
    },
    activeUserHasCompletedOutcome: function ({ context }) {
      return (
        context.consultationOutcomeResponse?.outcome ===
        MemberConsultationOutcome.DIGITAL_CONSULT_ENDED
      );
    },
    selectedConsultationHasNoRefund: function ({ context }) {
      return (
        context.selectedConsultation?.outcome ===
        MemberConsultationOutcome.NO_REFUND
      );
    },
    hasActiveConsultations: function ({ context }) {
      return context.activeConsultationListResponse.length > 0;
    },
    userHasCompletedOutcome: function ({ context }) {
      return (
        context.consultationOutcomeResponse?.outcome ===
          MemberConsultationOutcome.DIGITAL_CONSULT_ENDED ||
        context.consultationOutcomeResponse?.outcome ===
          MemberConsultationOutcome.NO_REFUND
      );
    },
    userHasOutstandingRefund: function ({ context }) {
      return (
        context.consultationOutcomeResponse?.outcome ===
        MemberConsultationOutcome.REFUND_REQUESTED
      );
    },
    timeoutExceeded: function ({ context }) {
      const currentDate = new Date();
      const consultationStartTime = context.startConsultationTime;

      if (consultationStartTime) {
        return (
          Number(currentDate.getTime() - consultationStartTime.getTime()) /
            1000 >
          MAX_POLLING_TIME_IN_SECONDS
        );
      }

      return false;
    },
    userHasNotMadePayment: function ({ context }) {
      return (
        context.consultationOutcomeResponse?.outcome ===
        MemberConsultationOutcome.PAYMENT_REQUESTED
      );
    },
    userHasMadePayment: function ({ context }) {
      return (
        context.consultationOutcomeResponse?.outcome ===
        MemberConsultationOutcome.PAYMENT_SUCCEEDED
      );
    },
    hasNoActiveConsultations: function ({ context }) {
      return context.activeConsultationListResponse.length === 0;
    },
    isDirectToDoctorSelected: function ({ context }) {
      return context.isDirectToDoctorSelected;
    },
    hasPrimarySymptoms: function ({ context }) {
      return context.primarySymptomIds.length > 0;
    },
    isPromoCodeFeatureEnabled: function () {
      const isPromoCodesFeatureEnabled = isFeatureEnabled(
        FeatureToggleIdentifier.ENABLE_PROMO_CODES
      );

      return isPromoCodesFeatureEnabled;
    },
    isPromoCodeFeatureEnabledAndHasValidPromoCode: function ({ context }) {
      const isPromoCodesFeatureEnabled = isFeatureEnabled(
        FeatureToggleIdentifier.ENABLE_PROMO_CODES
      );
      let hasValidPromoCode = false;

      if (context.memberServiceOfferingUsages !== null) {
        const currentDate = new Date();

        hasValidPromoCode = context.memberServiceOfferingUsages.some(
          (promo) => {
            const availableFrom = new Date(promo.availableFrom);
            const availableTo = new Date(promo.availableTo);

            return (
              promo.usageStatus === MemberServiceOfferingStatus.ACTIVE &&
              currentDate >= availableFrom &&
              currentDate <= availableTo
            );
          }
        );
      }

      return isPromoCodesFeatureEnabled && hasValidPromoCode;
    },
  },
  schemas: {},
  delays: {
    CONSULTATION_INTERVAL: POLLING_INTERVAL_IN_MILLISECONDS,
  },
}).createMachine({
  context: initialContextValues,
  id: "intercareFlow",
  initial: "start",
  states: {
    start: {
      entry: assign(({ event }) => {
        const entryContext = { ...initialContextValues };
        const { primarySymptomIds = [], queryParamConsultationId = "" } = event
          .input?.context
          ? event.input.context
          : {};
        return {
          ...entryContext,
          queryParamConsultationId,
          primarySymptomIds,
        };
      }),
      invoke: {
        id: "start",
        input: ({ context }) => ({ context }),
        onDone: {
          target: "fetchingMemberDetails",
          actions: {
            type: "addActiveConsultationListResponseToContext",
          },
        },
        onError: {
          target: "getActiveConsultationsError",
        },
        src: "getActiveConsultations",
      },
    },
    fetchingMemberDetails: {
      invoke: {
        id: "fetchingMemberDetails",
        input: ({ context }) => ({ context }),
        onDone: {
          target:
            "fetchingMemberServiceOfferingUsageOrSelectingConsultationType",
          actions: {
            type: "addMembersListToContext",
          },
        },
        onError: {
          target: "genericError",
        },
        src: "getMemberships",
      },
    },
    fetchingMemberServiceOfferingUsageOrSelectingConsultationType: {
      always: [
        {
          target: "fetchingMemberServiceOfferingUsage",
          guard: { type: "isPromoCodeFeatureEnabled" },
        },
        {
          target: "selectConsultationTypeOrDefault",
        },
      ],
    },
    fetchingMemberServiceOfferingUsage: {
      invoke: {
        id: "fetchingMemberServiceOfferingUsage",
        input: ({ context }) => ({ context }),
        onDone: {
          target: "selectConsultationTypeOrDefault",
          actions: {
            type: "addMemberServiceOfferingUsageResponseToContext",
          },
        },
        onError: {
          target: "selectConsultationTypeOrDefault",
        },
        src: "getMemberServiceOfferingUsage",
      },
    },
    selectConsultationTypeOrDefault: {
      always: [
        {
          target: "fetchingConsultationOutcome",
          guard: {
            type: "hasConsultationIdinQueryParam",
          },
        },
        {
          target:
            "promptingDirectToDoctorNextStepsWithPromoCodeOrSelectingDependents",
          guard: {
            type: "hasPrimarySymptoms",
          },
          actions: [{ type: "addIsDirectToDoctorToContext" }],
        },
        {
          target: "selectConsultationType",
          guard: {
            type: "hasNoActiveConsultations",
          },
        },
        {
          target: "selectingActiveConsultationOrStartingNewConsultation",
        },
      ],
    },
    selectConsultationType: {
      on: {
        [eventNames.BACK_BUTTON_CLICKED]: {
          target: "exit",
        },
        [eventNames.DIRECT_TO_DOCTOR_BUTTON_CLICKED]: [
          {
            target: "promptingPromoCode",
            guard: {
              type: "isPromoCodeFeatureEnabledAndHasValidPromoCode",
            },
          },
          {
            target: "promptingDirectToDoctorNextStepsOrSelectingDependents",
            actions: [{ type: "addIsDirectToDoctorToContext" }],
          },
        ],
        [eventNames.NURSE_CONSULTATION_BUTTON_CLICKED]: {
          target: "promptingNurseConsultNextStepsOrSelectingDependents",
        },
      },
    },
    promptingPromoCode: {
      on: {
        [eventNames.BACK_BUTTON_CLICKED]: {
          target: "selectConsultationType",
        },
        [eventNames.CONTINUE_BUTTON_CLICKED]: {
          target: "promptingDirectToDoctorNextStepsOrSelectingDependents",
          actions: [
            { type: "addIsPromoCodeAppliedToContext" },
            { type: "addIsDirectToDoctorToContext" },
          ],
        },
        [eventNames.SKIP_BUTTON_CLICKED]: {
          target: "promptingDirectToDoctorNextStepsOrSelectingDependents",
          actions: [{ type: "addIsDirectToDoctorToContext" }],
        },
      },
    },
    promptingNurseConsultNextStepsOrSelectingDependents: {
      always: [
        {
          target: "outsideOperatingHours",
          guard: {
            type: "isOutsideOperatingHours",
          },
        },
        {
          target: "selectingDependents",
          guard: {
            type: "hasDependents",
          },
        },
        {
          target: "promptingNurseConsultNextSteps",
        },
      ],
    },
    promptingDirectToDoctorNextStepsWithPromoCodeOrSelectingDependents: {
      always: [
        {
          target: "outsideOperatingHours",
          guard: {
            type: "isOutsideOperatingHours",
          },
        },
        {
          target: "selectingDependents",
          guard: {
            type: "hasDependents",
          },
        },
        {
          target: "promptingDirectToDoctorNextStepsWithPromoCode",
          guard: {
            type: "isPromoCodeFeatureEnabledAndHasValidPromoCode",
          },
        },
        {
          target: "promptingDirectToDoctorNextSteps",
        },
      ],
    },
    promptingDirectToDoctorNextStepsOrSelectingDependents: {
      always: [
        {
          target: "outsideOperatingHours",
          guard: {
            type: "isOutsideOperatingHours",
          },
        },
        {
          target: "selectingDependents",
          guard: {
            type: "hasDependents",
          },
        },
        {
          target: "promptingDirectToDoctorNextSteps",
        },
      ],
    },
    promptingDirectToDoctorNextStepsWithPromoCode: {
      on: {
        [eventNames.BACK_BUTTON_CLICKED]: {
          target: "exit",
        },
        [eventNames.CONTINUE_BUTTON_CLICKED]: {
          target: "promptingDirectToDoctorNextSteps",
          actions: [{ type: "addIsPromoCodeAppliedToContext" }],
        },
        [eventNames.SKIP_BUTTON_CLICKED]: {
          target: "promptingDirectToDoctorNextSteps",
          actions: [{ type: "addIsDirectToDoctorToContext" }],
        },
      },
    },
    promptingDirectToDoctorNextSteps: {
      on: {
        [eventNames.FIND_A_DOCTOR_BUTTON_CLICKED]: {
          target: "findADoctor",
        },
        [eventNames.CONTINUE_BUTTON_CLICKED]: {
          target: "startingConsultation",
          actions: [{ type: "addIsDirectToDoctorToContext" }],
        },
        [eventNames.BACK_BUTTON_CLICKED]: [
          { target: "exit", guard: { type: "hasPrimarySymptoms" } },
          {
            target: "selectConsultationType",
            actions: [{ type: "removeIsDirectToDoctorToContext" }],
          },
          {
            target: "selectConsultationTypeOrDefault",
          },
        ],
      },
    },
    selectingActiveConsultationOrStartingNewConsultation: {
      always: [
        {
          target: "outsideOperatingHours",
          guard: {
            type: "hasNoActiveConsultationsAndIsOutsideOperatingHours",
          },
        },
        {
          target: "selectingActiveConsultation",
          guard: {
            type: "hasAtLeastOneActiveConsultations",
          },
        },
        {
          target: "selectingDependents",
          guard: {
            type: "hasDependentsAndNoActiveConsultations",
          },
        },
        {
          target: "promptingNurseConsultNextSteps",
          guard: {
            type: "hasNoDependentsAndNoActiveConsultations",
          },
        },
      ],
    },
    selectingActiveConsultation: {
      on: {
        [eventNames.BACK_BUTTON_CLICKED]: {
          target: "exit",
        },
        [eventNames.ACTIVE_CONSULTATION_SELECTED]: {
          target: "activeConsultationOutcome",
          actions: [
            { type: "addSelectedConsultationToContext" },
            { type: "addConsultationOutcomeResponseToContext" },
          ],
        },
        [eventNames.START_CONSULTATION_BUTTON_CLICKED]: {
          target: "selectingConsultationTypeOrOutsideOperatingHours",
        },
      },
    },
    selectingConsultationTypeOrOutsideOperatingHours: {
      always: [
        {
          target: "outsideOperatingHours",
          guard: {
            type: "hasActiveConsultationsAndIsOutsideOperatingHours",
          },
        },
        {
          target: "selectConsultationType",
        },
      ],
    },
    activeConsultationOutcome: {
      always: [
        {
          target: "outsideOperatingHours",
          guard: {
            type: "hasActiveConsultationsAndIsOutsideOperatingHours",
          },
        },
        {
          target: "outstandingPayment",
          guard: {
            type: "selectedConsultationHasOutstandingPayment",
          },
        },
        {
          target: "outstandingRefund",
          guard: {
            type: "selectedConsultationHasOutstandingRefund",
          },
        },
        {
          target: "promptingNurseConsultNextSteps",
          guard: {
            type: "selectedConsultationHasOutstandingPaymentWithNoCharge",
          },
        },
        {
          target: "findAHospital",
          guard: {
            type: "userHasFindAHospitalOutcome",
          },
        },
        {
          target: "paymentFailure",
          guard: {
            type: "userHasUnsuccessfulPaymentOutcome",
          },
        },
        {
          target: "promptingFindADoctor",
          guard: {
            type: "userHasFindADoctorOutcome",
          },
        },
        {
          target: "serviceRating",
          guard: {
            type: "activeUserHasCompletedOutcome",
          },
        },
        {
          target: "exit",
          guard: {
            type: "selectedConsultationHasNoRefund",
          },
        },
      ],
      on: {
        [eventNames.USER_REDIRECTED]: {
          target: "waitingForConsultationOutcome",
        },
      },
    },
    outstandingPayment: {
      on: {
        [eventNames.BACK_BUTTON_CLICKED]: {
          target: "selectingActiveConsultation",
        },
        [eventNames.USER_REDIRECTED]: {
          target: "waitingForConsultationOutcome",
        },
        [eventNames.GO_HOME_BUTTON_CLICKED]: {
          target: "exit",
        },
      },
    },
    promptingNurseConsultNextSteps: {
      always: {
        target: "outsideOperatingHours",
        guard: {
          type: "hasActiveConsultationsAndIsOutsideOperatingHours",
        },
      },
      invoke: {
        id: "promptingNurseConsultNextSteps",
        input: ({ context }) => ({ context }),
        onDone: {
          actions: {
            type: "addConsultationRatesResponseToContext",
          },
        },
        onError: {
          target: "genericError",
        },
        src: "getMemberConsultationRates",
      },
      on: {
        [eventNames.BACK_BUTTON_CLICKED]: {
          target: "redirectFromPromptingNextSteps",
        },
        [eventNames.CONTINUE_BUTTON_CLICKED]: {
          target: "startingConsultation",
        },
        [eventNames.MORE_INFO_BUTTON_CLICKED]: {
          target: "promptingNurseConsultMoreInfo",
        },
      },
    },
    promptingNurseConsultMoreInfo: {
      on: {
        [eventNames.BACK_BUTTON_CLICKED]: {
          target: "promptingNurseConsultNextSteps",
        },
      },
    },
    redirectFromPromptingNextSteps: {
      always: [
        {
          target: "selectConsultationType",
          guard: {
            type: "hasNoDependentsAndNoActiveConsultations",
          },
        },
        {
          target: "selectingActiveConsultation",
          guard: {
            type: "hasActiveConsultations",
          },
        },
        {
          target: "selectingDependents",
          guard: {
            type: "hasDependentsAndNoActiveConsultations",
          },
        },
        {
          target: "selectConsultationType",
        },
      ],
    },
    selectingDependents: {
      on: {
        [eventNames.BACK_BUTTON_CLICKED]: {
          target: "exit",
        },
        [eventNames.DEPENDANT_SELECTED]: {
          target: "promptingDirectToDoctorOrNurseConsultNextSteps",
          actions: "addSelectedDependentToContext",
        },
      },
    },
    promptingDirectToDoctorOrNurseConsultNextSteps: {
      always: [
        {
          target: "promptingDirectToDoctorNextSteps",
          guard: {
            type: "isDirectToDoctorSelected",
          },
        },
        {
          target: "promptingNurseConsultNextSteps",
        },
      ],
    },
    getActiveConsultationsError: {
      on: {
        [eventNames.CONTINUE_BUTTON_CLICKED]: {
          target: "exit",
        },
      },
    },
    startingConsultation: {
      invoke: {
        id: "startingConsultation",
        input: ({ context }) => ({ context }),
        onDone: {
          target: "breakoutDisclaimer",
          actions: {
            type: "addStartConsultationResponseToContext",
          },
        },
        onError: {
          target: "genericError",
        },
        src: "startConsultation",
      },
      on: {
        [eventNames.GO_HOME_BUTTON_CLICKED]: {
          target: "exit",
        },
      },
    },
    breakoutDisclaimer: {
      on: {
        [eventNames.USER_REDIRECTED]: {
          target: "waitingForConsultationOutcome",
        },
        [eventNames.BACK_BUTTON_CLICKED]: {
          target: "redirectFromBreakoutDisclaimer",
        },
      },
    },
    redirectFromBreakoutDisclaimer: {
      always: [
        {
          target: "promptingNurseConsultNextSteps",
          guard: {
            type: "hasNoDependentsAndNoActiveConsultations",
          },
        },
        {
          target: "promptingNurseConsultNextSteps",
          guard: {
            type: "hasDependentsAndNoActiveConsultations",
          },
        },
        {
          target: "selectingActiveConsultation",
          guard: {
            type: "hasActiveConsultations",
          },
        },
      ],
    },
    waitingForConsultationOutcome: {
      after: {
        CONSULTATION_INTERVAL: {
          target: "fetchingConsultationOutcome",
        },
      },
      on: {
        [eventNames.GO_HOME_BUTTON_CLICKED]: {
          target: "exit",
        },
      },
    },
    fetchingConsultationOutcome: {
      invoke: {
        id: "fetchingConsultationOutcome",
        input: ({ context }) => ({ context }),
        onDone: {
          target: "processingConsultationOutcome",
          actions: {
            type: "addConsultationOutcomeResponseToContext",
          },
        },
        onError: {
          target: "genericError",
        },
        src: "getConsultationOutcome",
      },
      on: {
        [eventNames.GO_HOME_BUTTON_CLICKED]: {
          target: "exit",
        },
      },
    },
    processingConsultationOutcome: {
      always: [
        {
          target: "promptingNurseConsultNextSteps",
          guard: {
            type: "userHasArchivedConsultation",
          },
        },
        {
          target: "findAHospital",
          guard: {
            type: "userHasFindAHospitalOutcome",
          },
        },
        {
          target: "paymentFailure",
          guard: {
            type: "userHasUnsuccessfulPaymentOutcome",
          },
        },
        {
          target: "promptingFindADoctor",
          guard: {
            type: "userHasFindADoctorOutcome",
          },
        },
        {
          target: "serviceRating",
          guard: {
            type: "userHasCompletedOutcome",
          },
        },
        {
          target: "outstandingRefund",
          guard: {
            type: "userHasOutstandingRefund",
          },
        },
        {
          target: "exit",
          guard: {
            type: "timeoutExceeded",
          },
        },
        {
          target: "breakoutDisclaimer",
          guard: {
            type: "hasConsultationIdinQueryParam",
          },
        },
        {
          target: "waitingForConsultationOutcome",
        },
      ],
      on: {
        [eventNames.GO_HOME_BUTTON_CLICKED]: {
          target: "exit",
        },
      },
    },
    genericError: {
      on: {
        [eventNames.GO_HOME_BUTTON_CLICKED]: {
          target: "exit",
        },
      },
    },
    outsideOperatingHours: {
      on: {
        [eventNames.GO_HOME_BUTTON_CLICKED]: {
          target: "exit",
        },
      },
    },
    submittingUserRating: {
      invoke: {
        id: "submittingUserRating",
        input: ({ context }) => ({ context }),
        onDone: {
          target: "submitRatingComplete",
        },
        onError: {
          target: "submitRatingError",
        },
        src: "submitRating",
      },
    },
    submitRatingComplete: {
      on: {
        [eventNames.CONTINUE_BUTTON_CLICKED]: {
          target: "exit",
        },
      },
    },
    submitRatingError: {
      on: {
        [eventNames.RATING_TRY_AGAIN]: {
          target: "serviceRating",
        },
        [eventNames.GO_HOME_BUTTON_CLICKED]: {
          target: "exit",
        },
      },
    },
    outstandingRefund: {
      on: {
        [eventNames.CONTINUE_BUTTON_CLICKED]: {
          target: "exit",
        },
        [eventNames.BACK_BUTTON_CLICKED]: {
          target: "exit",
        },
      },
    },
    serviceRating: {
      on: {
        [eventNames.CONTINUE_BUTTON_CLICKED]: {
          target: "submittingUserRating",
          actions: "addUserRatingToContext",
        },
        [eventNames.GO_HOME_BUTTON_CLICKED]: {
          target: "exit",
        },
      },
    },
    findAHospital: {
      type: "final",
    },
    promptingFindADoctor: {
      on: {
        [eventNames.CONTINUE_BUTTON_CLICKED]: {
          target: "findADoctor",
        },
        [eventNames.BACK_BUTTON_CLICKED]: {
          target: "exit",
        },
      },
    },
    findADoctor: {
      on: {
        [eventNames.BACK_BUTTON_CLICKED]: [
          {
            target: "promptingDirectToDoctorNextSteps",
            guard: {
              type: "isDirectToDoctorSelected",
            },
          },
          { target: "promptingFindADoctor" },
        ],
      },
    },
    paymentFailure: {
      on: {
        [eventNames.GO_HOME_BUTTON_CLICKED]: {
          target: "exit",
        },
      },
    },
    exit: {
      type: "final",
    },
  },
});

import { convertPxToRem } from "@/utils";
import { Box, Typography } from "@mui/material";
import IconLoader from "../IconLoader";

interface StepsCounterProps {
  stepCounter: number;
  disabled?: boolean;
}

export default function StepsCounter({
  stepCounter,
  disabled = false,
}: StepsCounterProps) {
  return (
    <Box
      sx={{
        position: "relative",
        height: 30,
        width: 30,
      }}
    >
      <IconLoader
        icon="HandDrawnCircleIcon"
        sx={{
          color: disabled ? "neutral.100" : "accent2.light",
          fontSize: convertPxToRem(30),
          alignSelf: "center",
          position: "absolute",
          left: "0",
        }}
      />
      <Typography
        sx={{
          fontWeight: 700,
          position: "relative",
          zIndex: 1,
          top: "50%",
          left: "50%",
          textAlign: "center",
          transform: "translate(-50%, -50%)",
          color: disabled ? "neutral.300" : "accent2.dark",
          mr: 2,
        }}
      >
        {stepCounter}
      </Typography>
    </Box>
  );
}
